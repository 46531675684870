import { Page } from '../../../types/pages.ts';
import PageBlock from '../PageBlock';
import PageModal from '../PageModal';
import { useEffect, useState } from 'react';
import DashboardLayout from '../../_base/DashboardLayout';
import PrimaryText from '../../_base/PrimaryText';
import SecondaryText from '../../_base/SecondaryText';
import Button from '../../_base/Button';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';

const Page = ({ page }: { page: Page }) => {
    const navigate = useNavigate();

    const [isModalsOpen, setIsModalsOpen] = useState(
        [] as {
            SK: string;
            isOpen: boolean;
            onClose: () => void;
        }[],
    );

    useEffect(() => {
        if (page?.modals?.length > 0) {
            setIsModalsOpen(
                page?.modals?.map(({ SK, behaviour }) => ({
                    SK,
                    isOpen: !!behaviour?.isAutoShow,
                    onClose: () =>
                        setIsModalsOpen(
                            (prevState) =>
                                prevState?.map((state) => (state?.SK === SK ? { ...state, SK, isOpen: false } : state)),
                        ),
                })),
            );
        } else {
            setIsModalsOpen([]);
        }
    }, [page?.modals]);

    return (
        <DashboardLayout sideNavigation={page?.layout?.sideNavigation} isTopNavigation={page?.layout?.isTopNavigation}>
            {!page?.isHomePage && (
                <Button
                    size="sm"
                    onClick={() => navigate(-1)}
                    className="mb-2"
                    leftIcon={
                        <SecondaryText>
                            <ChevronLeftIcon className="h-4 w-4" />
                        </SecondaryText>
                    }>{``}</Button>
            )}
            <div className="flex flex-col gap-4">
                {(page?.title || page?.subtitle) && (
                    <div>
                        {page?.title && (
                            <PrimaryText block className="font-semibold text-3xl">
                                {page?.title}
                            </PrimaryText>
                        )}
                        {page?.subtitle && (
                            <SecondaryText block className="text-base">
                                {page?.subtitle}
                            </SecondaryText>
                        )}
                    </div>
                )}
                {page?.blocks?.map((block) => <PageBlock block={block} key={block?.SK} />)}
            </div>
            {page?.modals?.length > 0 &&
                isModalsOpen?.map(({ isOpen, onClose }, index) => (
                    <PageModal
                        modal={page.modals[index]}
                        key={page?.modals?.[index]?.SK}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                ))}
        </DashboardLayout>
    );
};

export default Page;

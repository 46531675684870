import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import { PageBlock, PageModal } from '../../../types/pages.ts';
import { useLocalStorage } from 'usehooks-ts';
import classNames from '../../../utils/classNames.ts';
import Button from '../../_base/Button';
import Loader from '../../_base/Loader';
import { useNavigate } from 'react-router-dom';
import IFrameView from '../../_blocks/IFrameView';
import addUrlSubstitutions from '../../../utils/addUrlSubstitutions.ts';

const PageModal = ({ isOpen, onClose, modal }: { isOpen: boolean; onClose: () => void; modal: PageModal }) => {
    const { theme } = useCreatorConfig();
    const navigate = useNavigate();
    const [isDoNotShowModal, setIsDoNotShowModal] = useLocalStorage(`${modal?.SK}-doNotShow`, false);
    const [pageModal, setPageModal] = useState(modal);
    const [isModalOpen, setIsModalOpen] = useState(isDoNotShowModal ? false : isOpen);

    const videoPlayerRef = useRef<HTMLVideoElement>(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [iFrameUrl, setIFrameUrl] = useState(null as string | null);

    useEffect(() => {
        setIsModalOpen(isDoNotShowModal ? false : isOpen);
        setPageModal(modal);
        setTimeout(() => {
            setIsDoNotShowModal(modal?.behaviour?.showFrequency === 'once');
        }, 1000);

        (async () => {
            if (modal?.iFrameUrl) setIFrameUrl(await addUrlSubstitutions(modal?.iFrameUrl));
        })();
    }, [modal, isOpen]);

    useEffect(() => {
        if (!isModalOpen) onClose();
    }, [isModalOpen]);

    useEffect(() => {
        const onLoadedDataEventListener = () => {
            if (videoPlayerRef?.current) setIsVideoLoaded(true);
        };

        if (videoPlayerRef?.current) videoPlayerRef.current.addEventListener('loadeddata', onLoadedDataEventListener);

        return () => {
            if (videoPlayerRef.current) {
                videoPlayerRef.current.removeEventListener('loadeddata', onLoadedDataEventListener);
            }
        };
    }, [videoPlayerRef?.current]);

    return (
        <Transition.Root show={isModalOpen || false} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={pageModal?.isCloseable ? onClose : () => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className={classNames(
                                    'relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 lg:mx-4',
                                    pageModal?.classNames,
                                )}
                                style={{
                                    backgroundColor: theme?.colours?.backgroundColour,
                                }}>
                                {pageModal?.isCloseable && (
                                    <div className="absolute right-0 top-0 pr-4 pt-4">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => setIsModalOpen(false)}>
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                )}
                                <div className="mt-6 sm:flex sm:items-start md:mt-0">
                                    <div className="w-full sm:ml-4 sm:mt-0">
                                        {modal?.title && (
                                            <Dialog.Title
                                                className="text-xl font-semibold"
                                                style={{
                                                    color: theme?.colours?.primaryTextColour,
                                                }}>
                                                {modal?.title}
                                            </Dialog.Title>
                                        )}
                                        {modal?.subtitle && (
                                            <Dialog.Title
                                                className="text-md"
                                                style={{
                                                    color: theme?.colours?.secondaryTextColour,
                                                }}>
                                                {modal?.subtitle}
                                            </Dialog.Title>
                                        )}
                                        {modal?.videoUrl && (
                                            <div className="mt-2 flex w-full items-center justify-center">
                                                <div className="aspect-video">
                                                    <video
                                                        ref={videoPlayerRef}
                                                        autoPlay
                                                        playsInline
                                                        muted
                                                        controls
                                                        width="500"
                                                        className={classNames(
                                                            'w-full',
                                                            isVideoLoaded ? 'block' : 'hidden',
                                                        )}
                                                        src={modal?.videoUrl}></video>
                                                </div>
                                                {!isVideoLoaded && (
                                                    <div className="my-10">
                                                        <Loader />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {iFrameUrl && (
                                            <div className="mt-2 h-[60vh]">
                                                <IFrameView
                                                    block={
                                                        {
                                                            data: [
                                                                {
                                                                    src: iFrameUrl,
                                                                },
                                                            ],
                                                        } as PageBlock
                                                    }
                                                />
                                            </div>
                                        )}
                                        {modal?.body && (
                                            <div className="mt-2" dangerouslySetInnerHTML={{ __html: modal?.body }} />
                                        )}
                                    </div>
                                </div>
                                {!!pageModal?.callToActions?.length && (
                                    <div className="mt-4 flex justify-end">
                                        {pageModal?.callToActions?.map((callToAction) => (
                                            <Button
                                                isPrimary={callToAction?.type === 'primary'}
                                                key={callToAction?.title}
                                                onClick={() => {
                                                    if (callToAction?.action === 'close') onClose();
                                                    if (callToAction?.action === 'link') {
                                                        if (callToAction?.link?.type === 'external') {
                                                            window.open(callToAction?.link?.target, '_blank');
                                                        } else {
                                                            navigate(callToAction?.link?.target!);
                                                        }
                                                    }
                                                }}>
                                                {callToAction?.title}
                                            </Button>
                                        ))}
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default PageModal;
